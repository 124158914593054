
import { Component, Vue } from 'vue-property-decorator'

@Component({
  filters: {
    genderFilters (value: number) {
      return value === 1 ? '男' : value === 2 ? '女' : ''
    }
  }
})
export default class List extends Vue {
  private searchInfo = {
    projectName: ''
  }

  private tableData =[]

  private page = 1
  private total = 0
  private size = 10
  private loading=false

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.visitor.selectAppUserByPage, {
      page: this.page,
      size: this.size,
      ...this.searchInfo
    }).then(res => {
      if (res) {
        this.total = res.total || 0
        this.tableData = res.list || []
      }
    }).finally(() => {
      this.loading = false
    })
  }

  add () {
    this.$router.push({ name: 'themeAdd' })
  }

  searchData () {
    this.page = 1
    this.getData()
  }
}
